<template>
  <form>
    <b-form-select v-model="locationKey" :options="locationsForSelection" :plain="true"
      @change="handleSelectChange($event)" class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0">
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump-locations'].tcSelectAnotherLocation }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'

export default {
  name: 'quick-jump-locations',
  data() {
    return {
      locationKey: null,
    }
  },
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-locations': {
            tcSelectAnotherLocation: 'Select another location',
          },
        }
      },
    },
  },
  async created() {
    await Promise.all([
      await this.loadPlacementLocationsByCamp(constantData.member_types.find((mt) => mt.text === 'All').value),
    ])
  },
  computed: {
    ...mapGetters({
      locationsForSelection: 'scriptureDistribution/locationsForSelection',
      placement_locations: 'scriptureDistribution/placement_locations',
    }),
  },
  methods: {
    ...mapActions({
      loadPlacementLocationsByCamp: 'scriptureDistribution/loadPlacementLocationsByCamp',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedOrgTypeKey: 'user/setSelectedOrgTypeKey',
    }),
    async handleSelectChange(evt) {
      let evtParts = evt.split('|')
      await this.setSelectedLocationKey(evtParts[0])
      await this.setSelectedOrgTypeKey(evtParts[1])
      this.$emit('location_changed', evt)
    },
  },
}
</script>






<style/>
